var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0 py-4 mx-6 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticClass:"pa-6",staticStyle:{"background":"white"}},[_vm._v("Proveedores "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Ten a la mano el nombre de tus proveedores al momento de capturar una compra o un producto nuevo.")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"append-icon":"mdi-magnify","label":"Buscador","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogProvider=true}}},[_vm._v("Agregar Nuevo")])],1),_c('v-data-table',{staticClass:"elevation-0 px-6",attrs:{"search":_vm.search,"expand-icon":"","headers":_vm.headers,"items":_vm.providers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"min-height":"0px!important","font-size":"14px"},attrs:{"to":{ path: '/shopping/'+ item.id}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('editProvider',{attrs:{"editedItem":_vm.editedItem},on:{"closeDialogProvider":_vm.closeDialogProvider}})],1)]},proxy:true}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen proveedores registrados. ")]),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],2),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogProvider),callback:function ($$v) {_vm.dialogProvider=$$v},expression:"dialogProvider"}},[_c('createProvider',{on:{"closeDialogProvider":_vm.closeDialogProvider}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }